import React, { useState} from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useReducer } from 'react';
import useApiViaje from '../../../api/operaciones/useApiViajes';
import FieldDropDown from '../../../shared/controls/fieldDropDown';
import FieldInputNumber from '../../../shared/controls/fieldInputNumber';
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from '../../../shared/forms/formReducer';
import FormHandler from "../../../shared/forms/formHandler";
import { agregarAnticipo } from "./viajeValidacionEsquema";
import FieldCheckBox from '../../../shared/controls/fieldCheckBox';
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import alerts from "../../../shared/alerts";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import swal from "sweetalert2";
const AgregarAnticipo = ({ viajeId, onSave, visible, hide , categoriaTipo}) => {
    const api = useApiViaje();
    const externals = [  
        { id: 3, nombre: "Cuenta Ximple" },
        { id: 1, nombre: "Provisional" },
        { id: 2, nombre: "Externo" },
    ];
    const defaultEntity = () => ({
        entity: {
            operador: null,
            concepto: null,
            monto: null,
            comentario: null,
            urgente: false,
            extProv: 3
        }
    });

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const [cuentas, setCuentas] = useState(null);
    const formHandler = new FormHandler({
        validationSchema: agregarAnticipo,
        api,
        dispatch
    });

    const consultarCuentas = async (id) => {
        const result = await api.cuentasBancarias(id);
        setCuentas(result);    
        entity.cuentas =result[0]
    }
    const actualizar = async (value,propertyName) => {  
        if (propertyName ==="operador" && (entity.extProv === 3) ){
            consultarCuentas(value.id);             
        }
        if (propertyName === "extProv") {
            dispatch({ type: 'update', value, name: propertyName });
        } else {
            dispatch({ type: 'update', value, name: propertyName });
        }   
       
        dispatch({ type: 'update', value: value, name: propertyName });
                
    };  
    const onBlur = async (event) => {
     const value = event.target.value;
     console.log(value)
        if (/^\d+$/.test(value)) {
           if (value.length === 18) {               
            if (isValidClabe(value)) {
            } else {
              await swal.fire({
                titleText: 'Atención.',
                text: 'Número CLABE no válido.',
                icon: 'warning',
              });
            }
          }
          else if (value.length === 16) {
            if (value.length === 16) {                  
              if (isValidCardNumber(value)) {
              } else {                    
                await swal.fire({
                  titleText: 'Atención.',
                  text: 'Número de tarjeta no válido.',
                  icon: 'warning',
                });
              }
            } else {
              await swal.fire({
                titleText: 'Atención.',
                text: 'La longitud del número es incorrecta.',
                icon: 'warning',
              });
            }
          } 
          else if (value.length >= 17) {
            await swal.fire({
              titleText: 'Atención.',
              text: 'La longitud del número es incorrecta.',
              icon: 'warning',
            });
          }
        } else {
          if (value.length >= 1) {
            await swal.fire({
              titleText: 'Atención.',
              text: 'Debe contener solo números.',
              icon: 'warning',
            });
          }
        } 
    }   
    function isValidCardNumber(cardNumber) {
        let sum = 0;
        let alternate = false;
     
        for (let i = cardNumber.length - 1; i >= 0; i--) {
            let n = parseInt(cardNumber[i], 10);
            if (alternate) {
                n *= 2;
                if (n > 9) n -= 9;
            }
            sum += n;
            alternate = !alternate;
        }
     
        return sum % 10 === 0;
    }
     
    function isValidClabe(clabe) {
        if (clabe.length !== 18) return false;
     
        const factors = [3, 7, 1];
        let sum = 0;
     
        for (let i = 0; i < 17; i++) {
            const n = parseInt(clabe[i], 10);
            sum += n * factors[i % 3];
        }
     
        let checkDigit = 10 - (sum % 10);
        if (checkDigit === 10) checkDigit = 0;
     
        return checkDigit === parseInt(clabe[17], 10);
    }
     
    const guardar = async () => {
        const seguir = await formHandler.validate(entity);
        if(entity.urgente){
            if (seguir.result) {
                if ((await alerts.preguntarSiNo("¿Está seguro de realizar la transferencia?")) === true) {
                    if (seguir.result) {
                        const nuevo = await api.agregarAnticipo(viajeId, entity);
                        nuevo.categoriaActual = categoriaTipo;
                        onSave(nuevo);
                        dispatch({
                            type: "update",
                            value: { ...defaultEntity }
                        });
                    }
                }   
         }         
        }
        else{
            if (seguir.result) {
                const nuevo = await api.agregarAnticipo(viajeId, entity);
                onSave(nuevo);
                dispatch({
                    type: "update",
                    value: { ...defaultEntity }
                });
            } 
        }             
    };

    const close = () => {
        dispatch({
            type: "update",
            value: { ...defaultEntity }
        });
        hide();
    }

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                <Button label={entity.urgente ? "Transferir" : "Guardar"}  onClick={guardar} autoFocus />
            </div>
        );
    }

    return (
        <Dialog header="Agregar gasto" visible={visible} style={{ width: '60vw' }} footer={renderFooterModal()} onHide={close}>       
               <div className="form-row">   
                <FieldCheckBox 
                        name="urgente" 
                        label="Urgente" 
                        value={entity.urgente} 
                        className="py-1" 
                        flexflow="row-reverse" 
                        colMd={12}
                        onChange={actualizar} 
                    />
                </div>  
                {entity.urgente && (
                      <div className="d-flex justify-content-center">
                        <div className={`form-group col-md-6 col-sm-12}`}>                
                            <FieldRadioButtonList
                                name="extProv"
                                colMd={12}
                                options={externals}
                                value={entity.extProv}
                                onChange={actualizar}
                            />    
                        </div> 
                    </div> 
                )}   
                <div className="form-row">
                { (entity.extProv ===3  || entity.extProv === 1)&&(
                    <>
                           <FieldDropDown 
                            name="operador"                            
                            value={entity.operador} 
                            source="operadoresSegmento" 
                            label="Operador"
                            required 
                            colMd={12} 
                            error={errors?.operador}
                            params={{ viajeid: viajeId }} 
                            onChange={actualizar} 
                        />
                           </>
                    )}
                    <>
                     {entity.urgente && entity.extProv ===3 &&(
                        <FieldDropDown
                            name="cuentas"
                            value={entity.cuentas}
                            options={cuentas}
                            label="Cuentas Operador"
                            required 
                            error={errors?.cuentas}
                            colMd={12}                          
                            onChange={actualizar}
                        />
                    )}
                    </>
                
                {entity.extProv===2 && (
                    <>
                        <FieldTextBox 
                            name="nombreexterno" 
                            value={entity.nombreexterno} 
                            label="Nombre"
                            required 
                            error={errors?.nombreexterno}
                            colMd={12}
                            onChange={actualizar} 
                        />
                    </>
                  )}
                {entity.urgente && (entity.extProv ===2 || entity.extProv===1) && (
                    <>
                        <FieldDropDown 
                            name="banco" 
                            source="bancos" 
                            value={entity.bancos} 
                            label="Banco" 
                            filter={true}
                            colMd={6} 
                            required 
                            error={errors?.banco}
                            onChange={actualizar} 
                        />
                        <FieldTextBox 
                            name="clabeAnticipo" 
                            value={entity.clabeAnticipo} 
                            label="Tarjeta o CLABE" 
                            colMd={6}
                            required 
                            error={errors?.clabeAnticipo}
                            onBlur={onBlur}
                            onChange={actualizar} 
                        />
                    </>
                )}
                
                
                <FieldDropDown name="tipoGasto" source="cajachica/tipoGastos" value={entity?.tipoGasto} label="Tipo de Gasto" 
                                     params={{ categoriaTipo: categoriaTipo }}   filter={true}
                    required colMd={6} error={errors?.tipoGasto } onChange={actualizar} />
                <FieldDropDown name="conceptoCajaChica" source="cajachica/conceptos" value={entity?.conceptoCajaChica} label="Concepto"  filter={true}
                    required colMd={6} error={errors?.conceptoCajaChica} params={{ tipoGastoId: entity?.tipoGasto?.id }}
                    onChange={actualizar} />
                <FieldInputNumber name="monto" value={entity.monto} fractionDigits={2} label="Monto"
                    required colMd={6} error={errors?.monto}
                    onChange={actualizar} />
                <FieldTextArea name="comentario"  label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                         error={errors?.comentario} onChange={actualizar} />
            </div>

        </Dialog>
    )
}

export default AgregarAnticipo;
