import React, { useEffect, useState } from "react";
import produce from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import * as numeral from "numeral";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { Checkbox } from "primereact/checkbox";
import constantesOperacion from "../../constantes";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import AgregarAnticipo from "./anticipos";
import AgregarCompensacion from "./compensacion";
import AgregarDescuento from "./descuento";
import moment from "moment";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import constantesAdministracion from "../../../administracion/constantes";
import PdfViewer from "../../../shared/componentes/pdfViewer";
const CostosAnticipos = ({ viaje, errors, actualizar, soloLectura = false }) => {
    const [totalCasetas, setTotalCasetas] = useState(0);
    const viajeVacioId = constantesOperacion.tipoProgramacionViaje.vacio;
    const [nuevoAnticipo, setNuevoAnticipo] = useState(false);
    const [nuevaCompensacion, setNuevaCompensacion] = useState(false);
    const [nuevoDescuento, setnuevoDescuento] = useState(false);
    const api = useApiViaje();
    const viajeId = viaje.id;
    const [idGasto, setGasto] = useState(0);
    const [pdfComprobanteVisible, setPdfComprobanteVisible] = useState(false);
    const [categoriaTipo, setCategoriaTipo] = useState(null);
    useEffect(() => {
        obtenerTotalCasetas(viaje.casetas)
    }, [viaje.casetas]);

    function obtenerTotalCasetas(casetas) {
        const costoCasetas = casetas.reduce((suma, e) => {
            const costo = e.seleccionada ? e.costoCaseta : 0;
            return costo + suma;
        },
            0);

        setTotalCasetas(costoCasetas);
    }

    function seleccionarCaseta(e, item, index) {
        const checked = e.target.checked;

        const casetas = produce(viaje.casetas, draft => {
            draft[index].seleccionada = checked;
        }
        );

        actualizar(casetas, 'casetas');
    }

    function agregarAnticipo(anticipo) {
        if (anticipo.categoriaActual === constantesAdministracion.categorias.Operativos){
            const anticipos = produce(viaje.anticiposOperaciones, draft => {
                draft.push(anticipo);
            }
            );
            actualizar(anticipos, 'anticiposOperaciones');
        }
        if (anticipo.categoriaActual === constantesAdministracion.categorias.Mantenimiento){
            const anticipos = produce(viaje.anticiposMantenimiento, draft => {
                draft.push(anticipo);
            }
            );
            actualizar(anticipos, 'anticiposMantenimiento');
        }
        if (anticipo.categoriaActual === constantesAdministracion.categorias.Seguridad){
            const anticipos = produce(viaje.anticiposSeguridad, draft => {
                draft.push(anticipo);
            }
            );
            actualizar(anticipos, 'anticiposSeguridad');
        }
        setNuevoAnticipo(false);
    }

    function seuEditable() {
        const estatus = constantesOperacion.estatusViajes;
        return viaje.tipoViajeId !== viajeVacioId && viaje.estatus !== estatus.terminado
            && viaje.estatus !== estatus.cancelado
    }

    function cerrar() {
        setNuevoAnticipo(false);
    };

    function cerrarCompensacion() {
        setNuevaCompensacion(false);
    };

    function agregarCompensacion(compensacion) {
        const compensaciones = produce(viaje.compensaciones, draft => {
            draft.push(compensacion);
        }
        );

        actualizar(compensaciones, 'compensaciones');
        setNuevaCompensacion(false);
    }

    function cerrarDescuento() {
        setnuevoDescuento(false);
    }

    function agregarDescuento(descuento) {
        const descuentos = produce(viaje.descuentos, draft => {
            draft.push(descuento);
        }
        );

        actualizar(descuentos, 'descuentos');
        setnuevoDescuento(false);
    }

    const actualizarComision = async () => {
        if (viaje.id > 0)
            await api.actualizarComision(viaje.id, { comision: viaje.comision });
    };
    const verComprobante = (id) => {
        setGasto(id)
        setPdfComprobanteVisible(true);
    };
    const descargarPdf = async () => {
        await api.pdfComprobante(idGasto);
        setPdfComprobanteVisible(false);
    };
    const abrirGasto = async (categoria) => { 
        setNuevoAnticipo(true);
        setCategoriaTipo(categoria);
    };
    
    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Costos del Viaje</h5>

                <FieldInputNumber name="comision" label="Comisión (MXN)" value={viaje.comision} colMd={5}
                    disabled={(viaje.deshabilitado || viaje.esSegmentado) || soloLectura} onBlur={actualizarComision}
                    fractionDigits={2} error={errors?.comision} onChange={actualizar} />

                {viaje.tipoViajeId !== viajeVacioId &&
                    <div className="form-group 2 mt-3">
                        <FieldCheckBox name="esSeu" label="SEU" value={viaje.esSeu}
                            onChange={actualizar} disabled={!seuEditable() || soloLectura} />
                    </div>
                }

                {viaje.esSeu &&
                    <FieldInputNumber name="facturacion" label="Total a Facturar" value={viaje.facturacion} colMd={5}
                        onChange={actualizar} fractionDigits={2} disabled={!seuEditable() || soloLectura} />
                }
            </div>

            {viaje.casetas.length > 0 &&
                <>
                    <div className="form-row">
                        <h5 className="col-12">Autopistas Autorizadas</h5>
                    </div>

                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th />
                                <th scope="col" style={{ width: '50%' }}>CONCEPTO</th>
                                <th className="text-center" scope="col" style={{ width: '40%' }}>COSTO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viaje.casetas?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Checkbox checked={item.seleccionada} disabled={!viaje.esEditable || soloLectura}
                                                onChange={(e) => seleccionarCaseta(e, item, index)} />
                                        </td>
                                        <td>
                                            {item.direccion}
                                        </td>
                                        <td className="text-right">
                                            {numeral(item.costoCaseta).format('$0,0')}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="text-right" colSpan={2}>Total</th>
                                <th className="text-right">{numeral(totalCasetas).format('$0,0')}</th>
                            </tr>
                        </tfoot>
                    </table>
                </>
            }

            <div className="form-row mt-2">
                <div className="col-11">
                    <h5>Gastos Operativos </h5>
                </div>
                <div className="col-1 align-items-end header-icons">
                    {((viaje?.id > 0 && viaje?.cartaPorte > 0 && !viaje.deshabilitado) && !soloLectura) &&
                        <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonIcon title={'Agregar Gasto'} iconName={'SiAddthis'} className="grid-action"
                                 onClick={() => abrirGasto(constantesAdministracion.categorias.Operativos)} />
                        </div>
                    }
                </div>
            </div>

            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th style={{ width: '16%' }}>OPERADOR</th>
                        <th style={{ width: '10%' }}>TIPO DE GASTO</th>
                        <th style={{ width: '14%' }}>CONCEPTO</th>
                        <th style={{ width: '8%', textAlign: 'center' }}>MONTO</th>
                        <th style={{ width: '18%' }}>COMENTARIO</th>
                        <th style={{ width: '8%' }}>SOLICITANTE</th>
                        <th style={{ width: '14%' }}>FECHA DE SOLICITUD</th>
                        <th style={{ width: '6%' }}>URGENTE</th>
                        <th style={{ width: '6%' }}>NO SOLICITUD</th>
                        <th style={{ width: '6%' }}>ESTATUS</th>
                        <th style={{ width: '6%' }}>STP</th>
                        <th style={{ width: '6%' }}>INFORMACIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    {viaje.anticiposOperaciones?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.operador?.nombre ?? item.nombreExterno}</td>
                                <td>
                                    {item.tipoGasto !== null &&
                                        <span>{item.tipoGasto?.nombre}</span>
                                    }
                                </td>
                                <td>
                                    {item.concepto !== null &&
                                        <span>{item.concepto?.nombre}</span>
                                    }

                                    {item.conceptoCajaChica !== null &&
                                        <span>{item.conceptoCajaChica?.nombre}</span>
                                    }
                                </td>
                                <td style={{ textAlign: 'right' }}>{numeral(item.monto).format('$ 0,0.00')}</td>
                                <td>{item.comentario}</td>
                                <td>{item.usuarioSolicito}</td>
                                <td>{moment(item.fechaSolicitud).format('DD/MM/YYYY h:mm a')}</td>
                                <td style={{ textAlign: 'center' }}>
                                    {item.urgente &&
                                        <span>SI</span>
                                    }
                                    {!item.urgente &&
                                        <span>NO</span>
                                    }
                                </td>
                                <td>{item.noSolicitud}</td>
                                <td>{item.estatus?.nombre}</td>
                                <td style={{ width: 75 }}>
                                    <div className="d-flex mb-1">
                                        <div className="d-flex align-items-center">
                                            <>
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.solicitada && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_blank.png"
                                                            alt={constantesAdministracion.estatusStp.solicitada}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}

                                                 {item.estatusStp?.id === constantesAdministracion.estatusStp.liquidada && (
                                                    <>
                                                     <>{item.estatusStp?.nombre}</>
                                                        
                                                        <img 
                                                            src="../../../../img/stp_checked.png"
                                                            alt={constantesAdministracion.estatusStp.liquidada}
                                                            width='20px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}                                                
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.cancelada && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_rejected.png"
                                                            alt={constantesAdministracion.estatusStp.cancelada}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.devuelta && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_pending.png"
                                                            alt={constantesAdministracion.estatusStp.devuelta}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: 60}}>
                                
                                    <div className="d-flex justify-content-center">
                                        {item.estatusStp?.id === constantesAdministracion.estatusStp.liquidada &&
                                            <button className="btn btn-link" title={'Ver Comprobante'}  onClick={() => verComprobante(item.id)}>
                                                <i className="pi pi-file text-primary"/>
                                            </button>
                                        }
                                         {(item.estatusStp?.id === constantesAdministracion.estatusStp.devuelta || item.estatusStp?.id === constantesAdministracion.estatusStp.cancelada) &&
                                             <td>{item.descripcionErrorStp}</td>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div className="form-row mt-2">
                <div className="col-11">
                    <h5>Gastos Mantenimiento </h5>
                </div>
                <div className="col-1 align-items-end header-icons">
                    {((viaje?.id > 0 && viaje?.cartaPorte > 0 && !viaje.deshabilitado) && !soloLectura) &&
                        <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonIcon title={'Agregar Gasto'} iconName={'SiAddthis'} className="grid-action"
                               onClick={() => abrirGasto(constantesAdministracion.categorias.Mantenimiento)}  />
                        </div>
                    }
                </div>
            </div>

            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th style={{ width: '16%' }}>OPERADOR</th>
                        <th style={{ width: '10%' }}>TIPO DE GASTO</th>
                        <th style={{ width: '14%' }}>CONCEPTO</th>
                        <th style={{ width: '8%', textAlign: 'center' }}>MONTO</th>
                        <th style={{ width: '18%' }}>COMENTARIO</th>
                        <th style={{ width: '8%' }}>SOLICITANTE</th>
                        <th style={{ width: '14%' }}>FECHA DE SOLICITUD</th>
                        <th style={{ width: '6%' }}>URGENTE</th>
                        <th style={{ width: '6%' }}>NO SOLICITUD</th>
                        <th style={{ width: '6%' }}>ESTATUS</th>                        
                        <th style={{ width: '6%' }}>STP</th>
                        <th style={{ width: '6%' }}>INFORMACIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    {viaje.anticiposMantenimiento?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.operador?.nombre ?? item.nombreExterno}</td>
                                <td>
                                    {item.tipoGasto !== null &&
                                        <span>{item.tipoGasto?.nombre}</span>
                                    }
                                </td>
                                <td>
                                    {item.concepto !== null &&
                                        <span>{item.concepto?.nombre}</span>
                                    }

                                    {item.conceptoCajaChica !== null &&
                                        <span>{item.conceptoCajaChica?.nombre}</span>
                                    }
                                </td>
                                <td style={{ textAlign: 'right' }}>{numeral(item.monto).format('$ 0,0.00')}</td>
                                <td>{item.comentario}</td>
                                <td>{item.usuarioSolicito}</td>
                                <td>{moment(item.fechaSolicitud).format('DD/MM/YYYY h:mm a')}</td>
                                <td style={{ textAlign: 'center' }}>
                                    {item.urgente &&
                                        <span>SI</span>
                                    }
                                    {!item.urgente &&
                                        <span>NO</span>
                                    }
                                </td>
                                <td>{item.noSolicitud}</td>
                                <td>{item.estatus?.nombre}</td>
                                <td style={{ width: 75 }}>
                                    <div className="d-flex mb-1">
                                        <div className="d-flex align-items-center">
                                            <>
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.solicitada && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_blank.png"
                                                            alt={constantesAdministracion.estatusStp.solicitada}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}

                                                 {item.estatusStp?.id === constantesAdministracion.estatusStp.liquidada && (
                                                    <>
                                                     <>{item.estatusStp?.nombre}</>
                                                        
                                                        <img 
                                                            src="../../../../img/stp_checked.png"
                                                            alt={constantesAdministracion.estatusStp.liquidada}
                                                            width='20px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}                                                
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.cancelada && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_rejected.png"
                                                            alt={constantesAdministracion.estatusStp.cancelada}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.devuelta && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_pending.png"
                                                            alt={constantesAdministracion.estatusStp.devuelta}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: 60}}>
                                
                                    <div className="d-flex justify-content-center">
                                        {item.estatusStp?.id === constantesAdministracion.estatusStp.liquidada &&
                                            <button className="btn btn-link" title={'Ver Comprobante'}  onClick={() => verComprobante(item.id)}>
                                                <i className="pi pi-file text-primary"/>
                                            </button>
                                        }
                                        {(item.estatusStp?.id === constantesAdministracion.estatusStp.devuelta || item.estatusStp?.id === constantesAdministracion.estatusStp.cancelada) &&
                                             <td>{item.descripcionErrorStp}</td>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="form-row mt-2">
                <div className="col-11">
                    <h5>Gastos Seguridad </h5>
                </div>
                <div className="col-1 align-items-end header-icons">
                    {((viaje?.id > 0 && viaje?.cartaPorte > 0 && !viaje.deshabilitado) && !soloLectura) &&
                        <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonIcon title={'Agregar Gasto'} iconName={'SiAddthis'} className="grid-action"
                                 onClick={() => abrirGasto(constantesAdministracion.categorias.Seguridad)} />
                        </div>
                    }
                </div>
            </div>

            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th style={{ width: '16%' }}>OPERADOR</th>
                        <th style={{ width: '10%' }}>TIPO DE GASTO</th>
                        <th style={{ width: '14%' }}>CONCEPTO</th>
                        <th style={{ width: '8%', textAlign: 'center' }}>MONTO</th>
                        <th style={{ width: '18%' }}>COMENTARIO</th>
                        <th style={{ width: '8%' }}>SOLICITANTE</th>
                        <th style={{ width: '14%' }}>FECHA DE SOLICITUD</th>
                        <th style={{ width: '6%' }}>URGENTE</th>
                        <th style={{ width: '6%' }}>NO SOLICITUD</th>
                        <th style={{ width: '6%' }}>ESTATUS</th>
                        <th style={{ width: '6%' }}>STP</th>
                        <th style={{ width: '6%' }}>INFORMACIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    {viaje.anticiposSeguridad?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.operador?.nombre ?? item.nombreExterno}</td>
                                <td>
                                    {item.tipoGasto !== null &&
                                        <span>{item.tipoGasto?.nombre}</span>
                                    }
                                </td>
                                <td>
                                    {item.concepto !== null &&
                                        <span>{item.concepto?.nombre}</span>
                                    }

                                    {item.conceptoCajaChica !== null &&
                                        <span>{item.conceptoCajaChica?.nombre}</span>
                                    }
                                </td>
                                <td style={{ textAlign: 'right' }}>{numeral(item.monto).format('$ 0,0.00')}</td>
                                <td>{item.comentario}</td>
                                <td>{item.usuarioSolicito}</td>
                                <td>{moment(item.fechaSolicitud).format('DD/MM/YYYY h:mm a')}</td>
                                <td style={{ textAlign: 'center' }}>
                                    {item.urgente &&
                                        <span>SI</span>
                                    }
                                    {!item.urgente &&
                                        <span>NO</span>
                                    }
                                </td>
                                <td>{item.noSolicitud}</td>
                                <td>{item.estatus?.nombre}</td>
                                <td style={{ width: 75 }}>
                                    <div className="d-flex mb-1">
                                        <div className="d-flex align-items-center">
                                            <>
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.solicitada && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_blank.png"
                                                            alt={constantesAdministracion.estatusStp.solicitada}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}

                                                 {item.estatusStp?.id === constantesAdministracion.estatusStp.liquidada && (
                                                    <>
                                                     <>{item.estatusStp?.nombre}</>
                                                        
                                                        <img 
                                                            src="../../../../img/stp_checked.png"
                                                            alt={constantesAdministracion.estatusStp.liquidada}
                                                            width='20px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}                                                
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.cancelada && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_rejected.png"
                                                            alt={constantesAdministracion.estatusStp.cancelada}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}
                                                {item.estatusStp?.id === constantesAdministracion.estatusStp.devuelta && (
                                                    <>
                                                        {item.estatusStp?.nombre}
                                                        <img 
                                                            src="../../../../img/stp_pending.png"
                                                            alt={constantesAdministracion.estatusStp.devuelta}
                                                            width='15px' 
                                                            style={{ marginLeft: '2px' }} 
                                                        />
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: 60}}>
                                
                                    <div className="d-flex justify-content-center">
                                        {item.estatusStp?.id === constantesAdministracion.estatusStp.liquidada &&
                                            <button className="btn btn-link" title={'Ver Comprobante'}  onClick={() => verComprobante(item.id)}>
                                                <i className="pi pi-file text-primary"/>
                                            </button>
                                        }
                                        {(item.estatusStp?.id === constantesAdministracion.estatusStp.devuelta || item.estatusStp?.id === constantesAdministracion.estatusStp.cancelada) &&
                                             <td>{item.descripcionErrorStp}</td>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="form-row mt-2">
                <div className="col-11">
                    <h5>Compensaciones</h5>
                </div>
                <div className="col-1 align-items-end header-icons">
                    {((viaje?.id > 0 && viaje?.cartaPorte > 0 && !viaje.deshabilitado) && !soloLectura) &&
                        <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonIcon title={'Agregar Compensacion'} iconName={'SiAddthis'} className="grid-action"
                                onClick={() => setNuevaCompensacion(true)} />
                        </div>
                    }
                </div>
            </div>

            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th style={{ width: '15%' }}>OPERADOR</th>
                        <th style={{ width: '15%' }}>TIPO</th>
                        <th style={{ width: '15%' }}>CONCEPTO</th>
                        <th style={{ width: '10%', textAlign: 'center' }}>MONTO</th>
                        <th style={{ width: '15%' }}>COMENTARIO</th>
                        <th style={{ width: '8%' }}>SOLICITANTE</th>
                        <th style={{ width: '12%' }}>FECHA DE SOLICITUD</th>
                        <th style={{ width: '8%' }}>ESTATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {viaje.compensaciones?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.operador?.nombre}</td>
                                <td>{item.tipoBono?.nombre}</td>
                                <td>{item.compensacion?.nombre}</td>
                                <td style={{ textAlign: 'right' }}>{numeral(item.monto).format('$ 0,0.00')}</td>
                                <td>{item.comentario}</td>
                                <td>{item.usuarioSolicito}</td>
                                <td>{moment(item.fechaSolicitud).format('DD/MM/YYYY h:mm a')}</td>
                                <td>{item.estatus?.nombre}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div className="form-row mt-2">
                <div className="col-11">
                    <h5>Descuentos</h5>
                </div>
                <div className="col-1 align-items-end header-icons">
                    {((viaje?.id > 0 && viaje?.cartaPorte > 0 && !viaje.deshabilitado) && !soloLectura) &&
                        <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonIcon title={'Agregar Descuento'} iconName={'SiAddthis'} className="grid-action"
                                onClick={() => setnuevoDescuento(true)} />
                        </div>
                    }
                </div>
            </div>

            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th style={{ width: '15%' }}>OPERADOR</th>
                        <th style={{ width: '15%' }}>CONCEPTO</th>
                        <th style={{ width: '10%' }}>EXHIBICIONES</th>
                        <th style={{ width: '10%', textAlign: 'center' }}>MONTO</th>
                        <th style={{ width: '15%' }}>COMENTARIO</th>
                        <th style={{ width: '10%' }}>SOLICITANTE</th>
                        <th style={{ width: '15%' }}>FECHA DE SOLICITUD</th>
                        <th style={{ width: '10%' }}>ESTATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {viaje.descuentos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.operador?.nombre}</td>
                                <td>{item.descuento?.nombre}</td>
                                <td style={{ textAlign: 'center' }}>{item.exhibiciones}</td>
                                <td style={{ textAlign: 'right' }}>{numeral(item.monto).format('$ 0,0.00')}</td>
                                <td>{item.comentario}</td>
                                <td>{item.usuarioSolicito}</td>
                                <td>{moment(item.fechaSolicitud).format('DD/MM/YYYY h:mm a')}</td>
                                <td>{item.estatus?.nombre}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* Dialogo para agregar anticipos */}
            <AgregarAnticipo viajeId={viajeId} onSave={agregarAnticipo} visible={nuevoAnticipo} hide={cerrar} categoriaTipo={categoriaTipo} />
            {/* Dialogo para agregar compensaciones */}
            <AgregarCompensacion viajeId={viajeId} onSave={agregarCompensacion} visible={nuevaCompensacion} hide={cerrarCompensacion} />
            {/* Dialogo para agregar descuentos */}
            <AgregarDescuento viajeId={viajeId} onSave={agregarDescuento} visible={nuevoDescuento} hide={cerrarDescuento} />

              {/*Modal con la vista previa del comprobante*/}
              <PdfViewer header="Comprobante de Pago" 
             urlApi={`operaciones/viajes/programacion/${viajeId}/comprobanteStp`}
                       descargar={descargarPdf} visible={pdfComprobanteVisible}
                       hide={() => setPdfComprobanteVisible(false)}/>
        </>
    );
};


export default CostosAnticipos;
