/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState } from 'react'
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormDropDown from '../../../shared/forms/FormFields/FormDropDown';
import FormInputNumber from '../../../shared/forms/FormFields/FormInputNumber';
import FormCalendar from '../../../shared/forms/FormFields/FormCalendar';
import FormTextArea from '../../../shared/forms/FormFields/FormTextArea';

const Descuentos = ({value:descuentos, onChange, onValidate, soloLectura, diferencia, solicitudId,}) => {
    if (!descuentos.tieneDescuentos){
        console.log(diferencia)
        descuentos.monto= diferencia;
        descuentos.numeroExhibiciones=1;
        descuentos.solicitudId = solicitudId;
    }
    const [filtro, setFiltro] = useState({
        SolicitanteId: descuentos?.empleadoId?.solicitanteId,
        DestinatarioId: descuentos?.empleadoId?.destinatarioId,
    });
    const [selectedEmpleadoId, setSelectedEmpleadoId] = useState(null); // Estado para el empleado seleccionado
    
    const handleChange = (item) => {
        onChange({
            ...descuentos,
            ...item, // Esto mantiene los valores existentes y actualiza sólo lo necesario
        });
    };
;
    return (
        <>
            <Form
                columns={3}
                values={descuentos}
                onChange={handleChange}
                onValidate={onValidate}
                formDisabled={descuentos.tieneDescuentos || soloLectura}
            >
                <h5 style={{gridColumnEnd:'span 3'}}>
                    Descuentos
                </h5>              
                <Field
                    label="Empleado"
                    field="empleadoId"
                    source="cajachica/empleadosDescuentos"
                    params={filtro}
                    cols={3}
                    render={({ onChange, ...props }) => (
                        <FormDropDown
                            {...props}
                            value={selectedEmpleadoId} // Controla el valor del dropdown
                            onChange={(item) => {
                                onChange(item); // Actualiza el valor en el formulario
                                setSelectedEmpleadoId(item); // Actualiza el estado local del empleado seleccionado
                                setFiltro(prev => ({
                                    ...prev,
                                    SolicitanteId: item?.solicitanteId || prev.SolicitanteId,
                                    DestinatarioId: item?.destinatarioId || prev.DestinatarioId,
                                }));
                            }}
                            required={true}
                            cols={3}
                        />
                    )}
                />
                <Field label={"Descuento"} field={"genDescuentoId"}  source="cajachica/descuentos"  render={FormDropDown} cols={3} />
                <Field label={"Fecha de inicio de descuento"} field={"fechaInicio"} render={FormCalendar} cols={2} />
                <Field
                    label={"Monto"}
                    field={"monto"}
                    disabled={true}
                    render={FormInputNumber} 
                    cols={1}
                />
              
                <Field label={"No. Exhibiciones"} field={"numeroExhibiciones"}  render={FormInputNumber}
                  />
                   <Field
                field={"comentarios"}
                label={"Comentarios"}
                required={true}
                rows={3}
                cols={3}
                render={FormTextArea}
            />
    
            </Form>
        </>
    )
}

export default Descuentos;
